import React from "react";
import "./App.css";
import Home from "pages/Home"

import ReactGA from "react-ga4";


const App = () => {
  ReactGA.initialize("G-5M2ZRCL0CM");
  
  return (
    <div className="App">
      <Home />
    </div>
  );
};

export default App;
