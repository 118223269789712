import React from "react";

const MainLayout = ({ children }) => {

  return (
    <div className="bg-[#f8f8f8]">
      {children}
    </div>
  );
};

export default MainLayout;

