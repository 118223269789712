import React from 'react';
import Layout from "layout/MainLayout";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useWindowSize } from 'react-use'

const Home = () => {
  const imgUrl = process.env.PUBLIC_URL;
  const { width } = useWindowSize()
  const images =
    [
      { imge: imgUrl + 'slide10.png', name: "Chrome" },
      { imge: imgUrl + 'slide11.png', name: "Word Documents" },
      { imge: imgUrl + 'jiraa.png', name: "Jira Software" },
      { imge: imgUrl + 'slide13.png', name: "Trello" },
      { imge: imgUrl + 'slide14.webp', name: "Excel Sheets" },
      { imge: imgUrl + 'slide15.png', name: "Slack" },
      { imge: imgUrl + 'slide16.png', name: "Discord" },
      { imge: imgUrl + 'slide17.png', name: "Zoho" },
      { imge: imgUrl + 'slide18.png', name: "Powerpoint Slides" },
      { imge: imgUrl + 'slide19.png', name: "Hub Spot" },
      { imge: imgUrl + 'slide21.png', name: "Firefox" },
      { imge: imgUrl + 'slide22.png', name: "Skype" },
      { imge: imgUrl + 'slide23.png', name: "Monday.com" }
    ]

  const handleClick = () => {
    // Open the desired URL in a new tab
    window.open('https://github.com/Agile-Loop/Synapse-Copilot', '_blank');
  };

  const handleWidget = (val) => {
    if (!val) return
    if (val === "start") {
      window.open('https://github.com/Agile-Loop/Synapse-Copilot?tab=readme-ov-file#readme', '_blank');
    }
    if (val === "doc") {
      window.open('https://docs.agileloop.ai/introduction', '_blank');
    }
  };



  const settingsLeftToRight = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: width < 500 ? 1 : (width >= 500 && width < 1100) ? 2 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    rtl: false, // Set rtl to false for left to right movement
  };

  const settingsRightToLeft = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: width < 500 ? 1 : (width >= 500 && width < 1100) ? 2 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    rtl: true, // Set rtl to true for right to left movement
  };


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scrolling animation
    });
  }

  return (
    <Layout>

  <div className='bg-[#e6e6e6] px-3 py-1 rounded-2xl xs:w-[95%] sm:w-[70%] fixed bottom-3 z-150 left-1/2 transform -translate-x-1/2 flex items-center xs:justify-between sm:justify-between md:w-[450px] xs:hidden'>
    <div onClick={scrollToTop}>
    <img alt="circle" src={imgUrl + "/circle.png"} className=' h-[55px] xs:h-[25px] cursor-pointer' />
  </div>
  <p onClick={()=>handleWidget("start")} className='text-[14px] xs:text-[11px] font-bold font-MontBold 2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-5  cursor-pointer hover:text-secondary'>Ways to start</p>
  <p onClick={()=>handleWidget("doc")} className='text-[14px] xs:text-[11px] font-bold 2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-5  font-MontBold cursor-pointer hover:text-secondary'>Documentation</p>
  <div onClick={handleClick} className='cursor-pointer 2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-5  w-[55px] h-[55px] xs:w-[30px] xs:h-[30px] 2xl:flex xl:flex lg:flex md:flex sm:flex xs:flex bg-white shadow-card rounded-2xl flex items-center justify-center'>
    <img  alt="circle" src={imgUrl + "/githubfill.png"} />
  </div>
</div>
      {/**First Container */}
      <div className="bg-no-repeat bg-center bg-cover h-screen flex justify-center items-center" style={{ backgroundImage: 'url("/bg1.jpg")' }}>
        <div className='2xl:w-[70%] xl:w-[70%] lg:w-[80%] md:w-[80%] sm:w-[90%] xs:w-[90%]'>
          <img alt="logo" src={imgUrl + "logoWhite.png"} className='md:w-[280px] md:h-[70px] sm:w-[220px] sm:h-[50px] xs:w-[220px] xs:h-[50px]' />
          <p className='text-white  font-bold 2xl:text-[36px] xl:text-[36px] lg:text-[32px] md:text-[32px] sm:text-[24px] xs:text-[22px] 2xl:w-[75%] xl:w-[75%] lg:w-[80%] md:w-[80%] sm:w-full xs:w-full mt-4 font-MontBold'>Synapse - A Co-pilot for your SaaS Product with language as the new Interface.</p>
          <div onClick={handleClick} className='cursor-pointer rounded-3xl flex items-center py-2 w-[200px] justify-center bg-white mt-[70px]'>
            <p className='text-16px font-semibold font-MontBold'>Go to Github</p>
            <img className='ml-5' alt="git" src={imgUrl + "/github.png"} />
          </div>
        </div>
      </div>
      {/**PATNER Container */}
      <div className='my-[70px] xs:py-[0px] xs:pt-0'>
        <p className='text-[40px] xs:text-[24px] font-Mont font-extrabold text-center'>OUR PARTNERS</p>
        <div className='mt-10 2xl:w-[80%] xl:w-[75%] lg:w-[90%] md:w-[90%] sm:w-[80%] xs:w-[90%] mx-auto grid grid-cols-8 gap-1 items-center'>
          <img alt="@" src={imgUrl + "pi-1.jpeg"} className='2xl:col-span-1 xl:col-span-1 lg:col-span-1 md:col-span-1 sm:col-span-2 xs:col-span-2 w-full  object-cover' />
          <img alt="@" src={imgUrl + 'pi-2.jpeg'} className='2xl:col-span-1 xl:col-span-1 lg:col-span-1 md:col-span-1 sm:col-span-2 xs:col-span-2 w-full  object-cover' />
          <img alt="@" src={imgUrl + 'pi-3.jpeg'} className='2xl:col-span-1 xl:col-span-1 lg:col-span-1 md:col-span-1 sm:col-span-2 xs:col-span-2 w-full  object-cover' />
          <img alt="@" src={imgUrl + 'pi-4.jpeg'} className='2xl:col-span-1 xl:col-span-1 lg:col-span-1 md:col-span-1 sm:col-span-2 xs:col-span-2 w-full  object-cover' />
          <img alt="@" src={imgUrl + 'pi-5.jpeg'} className='2xl:col-span-1 xl:col-span-1 lg:col-span-1 md:col-span-1 sm:col-span-2 xs:col-span-2 w-full  object-cover' />
          <img alt="@" src={imgUrl + 'pi-6.jpg'}  className='2xl:col-span-1 xl:col-span-1 lg:col-span-1 md:col-span-1 sm:col-span-2 xs:col-span-2 w-full  object-cover' />
          <img alt="@" src={imgUrl + 'pi-7.jpeg'} className='2xl:col-span-1 xl:col-span-1 lg:col-span-1 md:col-span-1 sm:col-span-2 xs:col-span-2 w-full  object-cover' />
          <img alt="@" src={imgUrl + 'pi-8.jpeg'} className='2xl:col-span-1 xl:col-span-1 lg:col-span-1 md:col-span-1 sm:col-span-2 xs:col-span-2 w-full  object-cover' />
        </div>
      </div>

      {/**2end Container */}

      <>
        <div className='relative'>
          <img alt="@" src={imgUrl + "/line1.png"} className='2xl:block lg:block md:block sm:block xs:hidden' />
          <div className='2xl:w-[55%] xl:w-[55%] lg:w-[80%] md:w-[80%] sm:w-[80%] sm:mx-auto xs:w-[90%] mx-auto
          absolute  left-1/2 transform -translate-x-1/2 flex justify-center items-center
          2xl:top-10 xl:top-10 lg:top-0 md:top-0 sm:top-0 xs:top-0'>
            <p className='font-extrabold font-MontBold text-center
              2xl:text-[32px] xl:text-[32px] lg:text-[30px] md:text-[28px] sm:text-[24px] xs:text-[20px]'>
              It’s impossible to have the perfect UI/UX for your SaaS Product.
            </p>
          </div>
          <div className='absolute grid grid-cols-3  left-1/2 transform -translate-x-1/2
          2xl:top-[260px] xl:top-[250px] lg:top-[150px] md:top-[120px] sm:top-[100px] xs:top-[120px]
          2xl:gap-[50px] xl:gap-[50px] lg:gap-[50px] md:gap-[30px] sm:gap-10 xs:gap-10 
          2xl:w-[60%] xl:w-[70%] lg:w-[80%] md:w-[90%] sm:w-[80%] xs:w-[90%]'>
            <div className='md:col-span-1 sm:col-span-3 xs:col-span-3 bg-white p-5 rounded-3xl flex flex-col border-[#f0f0f0] border-2 h-[180px]'>
              <p className='font-extrabold 2xl:text-[40px] xl:text-[40px] lg:text-[30px] md:text-[28px] sm:text-[30px] xs:text-[30px]  text-center font-MontBold'>53%</p>
              <p className='text-14px text-center mt-2 font-Mont'>of the causes of customer churn are due to bad UI/UX</p>
            </div>
            <div className='md:col-span-1 sm:col-span-3 xs:col-span-3  bg-white p-5 rounded-2xl flex flex-col border-[#f0f0f0] border-2 h-[180px]'>
              <p className='font-extrabold 2xl:text-[40px] xl:text-[40px] lg:text-[30px] md:text-[28px] sm:text-[30px] xs:text-[30px] text-center font-MontBold'>~30-60</p>
              <p className='text-14px text-center mt-2 font-Mont'>Days for users to get the hang of a product’s UI.</p>
            </div>
            <div className='md:col-span-1 sm:col-span-3 xs:col-span-3 p-5 bg-white rounded-2xl flex flex-col border-[#f0f0f0] border-2 h-[180px]'>
              <p className='font-extrabold 2xl:text-[40px] xl:text-[40px] lg:text-[30px] md:text-[28px] sm:text-[30px] xs:text-[30px] text-center font-MontBold'>{`~$70,000`}</p>
              <p className='text-14px text-center mt-2 font-Mont'>Is spent every year by SaaS companies on updating UI/UX changes</p>
            </div>
          </div>
        </div>
        <div className='2xl:pb-[90px] xl:pb-[90px] lg:pb-[90px] md:pb-[30px] sm:pb:[30px] xs:pb-[10px] relative 
        2xl:pt-[90px] xl:pt-[90px] lg:pt-[120px] md:pt-[140px] sm:pt-[600px] xs:pt-[750px] '>
          <img alt="@" src={imgUrl + "/line2.png"} className='absolute z-10
          2xl:block xl:block lg:block md:block sm:hidden xs:hidden
        2xl:top-[350px] xl:top-[350px] lg:top-[380px] md:top-[400px] sm:top-[800px] xs:top-[400px] '/>
          <div className='flex flex-col justify-center items-center
          2xl:py-[70px] xl:py-[70px] lg:py-[70px] md:py-[50px] sm:py-0 xs:py-0
          2xl:pt-[0px] xl:pt-[0px] lg:pt-[0px] md:p-[70px] sm:pt-0 xs:pt-10

          '>
            <p className='font-extrabold text-center font-MontBold  
            2xl:text-[32px] xl:text-[32px] lg:text-[30px] md:text-[28px] sm:text-[24px] xs:text-[20px]
          2xl:w-[60%] xl:w-[70%] lg:w-[80%] md:w-[90%] sm:w-[80%] xs:w-[90%] '>
              Deploy your own Co-pilot in minutes with Synapse, so that you never have to worry about your UX.
            </p>
            <p className=' mt-10 font-extrabold text-center font-MontBold
            2xl:text-[32px] xl:text-[32px] lg:text-[30px] md:text-[28px] sm:text-[24px] xs:text-[20px]
            sm:w-[80%] sm:mx-auto xs:w-[90%] xs:mx-auto'>
              RPA is dying, Synapse is the future. </p>
          </div>
          <div className='grid grid-cols-3 mt-[50px] mx-auto
          2xl:gap-[50px] xl:gap-[50px] lg:gap-[50px] md:gap-[30px] sm:gap-10 xs:gap-10 
          2xl:w-[60%] xl:w-[70%] lg:w-[80%] md:w-[90%] sm:w-[80%] xs:w-[90%]'>
            <div className='md:col-span-1 sm:col-span-3 xs:col-span-3 px-10 py-5 bg-white rounded-3xl flex flex-col border-[#f0f0f0] z-10 border-2  h-[180px]'>
              <p className='font-extrabold 2xl:text-[40px] xl:text-[40px] lg:text-[32px] md:text-[28px] sm:text-[30px] xs:text-[30px] text-center font-MontBold'>~37%</p>
              <p className='text-14px text-center mt-2 font-Mont'>  reduction in resources to maintain your SaaS product.   </p>
            </div>
            <div className='md:col-span-1 sm:col-span-3 xs:col-span-3 px-10 py-5 bg-white rounded-3xl flex flex-col border-[#f0f0f0]z-10 border-2  h-[180px]'>
              <p className='font-extrabold 2xl:text-[40px] xl:text-[40px] lg:text-[32px] md:text-[28px] sm:text-[30px] xs:text-[30px] text-center font-MontBold'>~40%</p>
              <p className='text-14px text-center mt-2 font-Mont'>reduction in customer churn</p>
            </div>
            <div className='md:col-span-1 sm:col-span-3 xs:col-span-3 bg-white  px-10 py-5 rounded-3xl flex flex-col border-[#f0f0f0] z-10 border-2  h-[180px]'>
              <p className='font-extrabold 2xl:text-[40px] xl:text-[40px] lg:text-[32px] md:text-[28px] sm:text-[30px] xs:text-[30px] text-center font-MontBold'> ~9 hours</p>
              <p className='text-14px text-center mt-2 font-Mont'> saved each week from repetitive tasks. </p>
            </div>
          </div>

        </div>
        {/* <div className='flex justify-center items-center my-[60px] xs:my-[30px]'>
          <img src={imgUrl + "/jira.png"} alt="jira" className=' max-h-[50px] xs:max-h-[40px] object-fill' />

          <img src={imgUrl + "/trello.png"} alt="jira" className='ml-10 xs:ml-5  max-h-[50px] xs:max-h-[40px]  object-fill' />
          <img src={imgUrl + "/sale.png"} alt="jira" className='ml-10 xs:ml-5 max-h-[60px] xs:max-h-[50px] object-fill' />
        </div> */}
      </>

      {/**3rd Container */}

      <video
      className='w-[80%] xs:w-[90%] rounded-xl my-[70px] mx-auto'
      autoPlay
      muted
      controls
    >
      <source src={imgUrl + "/Trello-3x.mp4"} type="video/mp4" />
    </video>

      {/*4th Container */}

      <div className='w-[95%] mx-auto shadow-card rounded-xl py-10 2xl:px-[70px] xl:px-[70px] lg:px-[70px] md:px-[70px] sm:px-[30px] xs:px-[20px]'>
        <div className='grid grid-cols-2'>
          <div className='2xl:col-span-1 xl:col-span-1 lg:col-span-1 md:col-span-1 sm:col-span-2 xs:col-span-2'>
            <p className='font-extrabold text-[24px] xs:text-[20px] font-MontBold'>HOW WE BUILD WITH</p>
            <div className='flex items-center'>
              <p className='font-light text-[24px] xs:text-[20px] font-Mont'>SYNAPSE COPILOT</p>
              <img alt="@" src={imgUrl + "/arrow.png"} className='max-h-[40px] 2xl:ml-10 xl:ml-10 lg:ml-5  w-[150px] 2xl:block xl:block lg:block md:hidden sm:hidden xs:hidden' />
            </div>
          </div>
          <div className='2xl:col-span-1 xl:col-span-1 lg:col-span-1 md:col-span-1 sm:col-span-2 xs:col-span-2'>
            <p className='text-16px 2xl:mt-5 xl:mt-5 lg:mt-5 md:mt-0 sm:mt-5 xs:mt-5 font-Mont'>
              We want to make it much quicker for the developer community to integrate LLMs with any downstream APIs. It’s as simple as cloning the repository and installing the required dependencies.
            </p>
          </div>
        </div>
        <div className='grid grid-cols-4 mt-[70px] 2xl:gap-0 xl-gap-0 lg-gap-0 md:gap-10 sm-gap-10 xs:gap-10'>
          <div className='flex flex-col 2xl:col-span-1  xl:col-span-1 lg:col-span-2 md:col-span-2 sm:col-span-2 xs:col-span-4 justify-center items-center'>
            <div className='rounded-full bg-[#b1b1b1] p-[50px] xs:p-[25px]'>
              <img alt="icon" src={imgUrl + "/ni1.png"} className='object-contain w-[140px] h-[140px] xs:w-[100px] xs:h-[100px]'/>
            </div>
            <div className='flex w-full justify-center mt-3 items-center relative'>
              <img alt="arrow" src={imgUrl + "/arowSm.png"} className='max-w-[40px] absolute top-0 left-0 '/>
              <p className='font-extrabold text-[16px] font-MontBold mt-1'>1</p>
            </div>
            <p className='text-[14px] font-normal mt-3 font-Mont'>Click the link to our github </p>
          </div>
          <div className='flex flex-col 2xl:col-span-1 xl:col-span-1 lg:col-span-2 md:col-span-2 sm:col-span-2 xs:col-span-4  justify-center items-center'>
            <div className='rounded-full bg-[#b1b1b1] p-[50px] xs:p-[25px]'>
              <img alt="icon" src={imgUrl + "/ni2.png"} className='object-contain w-[140px] h-[140px] xs:w-[100px] xs:h-[100px]' />
            </div>
            <div className='flex w-full justify-center mt-3 items-center relative'>
              <img alt="arrow" src={imgUrl + "/arowSm.png"} className='max-w-[40px] absolute top-0 left-0 '/>
              <p className='font-extrabold text-[16px] font-MontBold mt-1'>2</p>
            </div>
            <p className='text-[14px] font-normal mt-3 font-Mont'>Navigate down to the read.me file </p>
          </div>
          <div className='flex flex-col 2xl:col-span-1 xl:col-span-1 lg:col-span-2 md:col-span-2 sm:col-span-2 xs:col-span-4  justify-center items-center'>
            <div className='rounded-full bg-[#b1b1b1]  p-[50px] xs:p-[25px]'>
              <img alt="icon" src={imgUrl + "/ni3.png"} className='object-contain w-[140px] h-[140px] xs:w-[100px] xs:h-[100px]' />
            </div>
            <div className='flex w-full justify-center mt-3 items-center relative'>
              <img alt="arrow" src={imgUrl + "/arowSm.png"} className='max-w-[40px] absolute top-0 left-0 '/>
              <p className='font-extrabold text-[16px] font-MontBold mt-1'>3</p>
            </div>
            <p className='text-[14px] font-normal mt-3 font-Mont'>Follow the steps! </p>
          </div>
          <div className='flex flex-col 2xl:col-span-1 xl:col-span-1 lg:col-span-2 md:col-span-2 sm:col-span-2 xs:col-span-4  justify-center items-center'>
            <div className='rounded-full bg-[#b1b1b1]  p-[50px] xs:p-[25px]'>
              <img alt="icon" src={imgUrl + "/ni4.png"} className='object-contain w-[140px] h-[140px] xs:w-[100px] xs:h-[100px]' />
            </div>
            <div className='flex w-full justify-center mt-3 items-center relative'>
              <img alt="arrow" src={imgUrl + "/arowSm.png"} className='max-w-[40px] absolute top-0 left-0 '/>
              <p className='font-extrabold text-[16px] font-MontBold mt-1'>4</p>
            </div>
            <p className='text-[14px] font-normal mt-3 font-Mont'>Start your automation journey. </p>
          </div>
        </div>
        <div className='2xl:justify-end xl:justify-end lg:justify-center md:justify-center sm:justify-center xs:justify-center   mt-[50px]
        2xl:hidden xl:hidden lg:hidden md:hidden sm:hidden xs:flex
        '>
          <div onClick={handleClick} className='rounded-3xl flex items-center border-2 cursor-pointer border-[#818181] py-1 w-[220px] justify-center bg-[#f8f8f8]'>
            <p className='text-16px font-semibold font-MontBold'>Go to Github</p>
            <img className='ml-5' alt="git" src={imgUrl + "/github.png"} />
          </div>
        </div>
      </div>
      
      {/*5th Container */}

      <>
      <div className='bg-primary py-[100px] xs:py-10 mt-[70px] '>
        <div className='2xl:w-[80%] xl:w-[80%] lg:w-[80%] md:w-[90%] sm:w-[90%] xs:w-[90%] mx-auto flex xs:flex-col  justify-between'>
          <div className='flex  w-[60%] xs:w-[100%]'>
            <p className='font-extrabold text-[24px] xs:text-[20px] text-white font-MontBold'>FEATURES</p>
            <img alt="@" src={imgUrl + "/arrow.png"} className='max-h-[40px] ml-10 w-[150px] 2xl:block xl:block lg:block md:block sm:block xs:hidden' />
          </div>
          <p className='text-white text-[16px] xs:mt-5 w-[40%] xs:w-[100%] font-Mont'>We’ve made it super simple for you to utilise our code and help us push boundaries and optimize performance.</p>
        </div>
        <img alt="line" src={imgUrl + "/line.png"} />
        <div className='grid grid-cols-4 gap-5 2xl:w-[80%] xl:w-[80%] lg:w-[90%] sm:w-[80%] xs:w-[90%] mx-auto mt-[50px]'>
          <div className='2xl:col-span-1 xl:col-span-1 xs:h-[280px] xs:flex xs:items-center xs:flex-col xs:justify-center lg:col-span-1 md:col-span-2 sm:col-span-2 xs:col-span-4 rounded-2xl bg-[#242326] border-2 border-[#312f35] p-3'>
            <p className='text-white font-extrabold text-[16px] text-center font-MontBold'>Seamless Integration</p>
            <p className='text-white font-normal mt-5 text-[16px] text-center font-Mont'> Integrate your own SaaS products.</p>
          </div>
          <div className='2xl:col-span-1 xl:col-span-1 xs:h-[280px] xs:flex xs:items-center xs:flex-col xs:justify-center lg:col-span-1 md:col-span-2 sm:col-span-2 xs:col-span-4 rounded-2xl bg-[#242326] border-2 border-[#312f35] p-3'>
            <p className='text-white font-extrabold text-[16px] text-center font-MontBold'>Instant Automation</p>
            <p className='text-white font-normal mt-5 text-[16px] text-center font-Mont'>  Leverage pre-integrated SaaS products such as Jira, Trello, and Salesforce. </p>
          </div>
          <div className='2xl:col-span-1 xl:col-span-1 xs:h-[280px] xs:flex xs:items-center xs:flex-col xs:justify-center lg:col-span-1 md:col-span-2 sm:col-span-2 xs:col-span-4 rounded-2xl bg-[#242326] border-2 border-[#312f35] p-3'>
            <p className='text-white font-extrabold text-[16px] text-center font-MontBold'>Easy Navigation</p>
            <p className='text-white font-normal mt-5 text-[16px] text-center font-Mont'> Allow users to navigate through with ease.</p>
          </div>
          <div className='2xl:col-span-1 xl:col-span-1 xs:h-[280px] xs:flex xs:items-center xs:flex-col xs:justify-center lg:col-span-1 md:col-span-2 sm:col-span-2 xs:col-span-4 rounded-2xl bg-[#242326] border-2 border-[#312f35] p-3'>
            <p className='text-white font-extrabold text-[16px] text-center font-MontBold'>Enhanced Control</p>
            <p className='text-white font-normal mt-5 text-[16px] text-center font-Mont'> gain more control over your projects through advanced Prompt Engineering. This feature allows you to tailor the functionality to your specific needs, offering flexibility and efficiency.</p>
          </div>
          <div className='col-span-4 mt-10 2xl:hidden xl:hidden lg:hidden md:hidden sm:hidden xs:flex 2xl:justify-end xl:justify-end lg:justify-end md:justify-center sm:justify-center xs:justify-center'>
            <div onClick={handleClick} className='rounded-3xl cursor-pointer flex items-center border-2 border-black py-2 w-[200px] justify-center bg-[#F5F5F5]'>
              <p className='text-16px font-semibold font-MontBold'>Go to Github </p>
              <img className='ml-5' alt="git" src={imgUrl + "/github.png"} />
            </div>
          </div>
        </div>
        <div className='mt-[70px] 2xl:w-[80%] xl:w-[80%] lg:w-[80%] md:w-[90%] sm:w-[90%] xs:w-[90%] mx-auto flex xs:flex-col  justify-between'>
          <div className='flex w-[60%] xs:w-[100%'>
            <p className='font-extrabold text-[24px] xs:text-[20px] text-white font-MontBold'>BENEFITS</p>
            <img alt="@" src={imgUrl + "/arrow.png"} className='max-h-[40px] ml-10 w-[150px] 2xl:block xl:block lg:block md:block sm:block xs:hidden' />
          </div>
          <p className='text-white text-[16px] w-[40%] xs:w-[100%] xs:mt-5 font-Mont'>We’ve open-sourced Synapse Co-Pilot to the global developer community because we want everyone to benefit from it. Synapse Co-pilot is designed in a way that cuts down the learning curve and enables you to build and customize your API scenarios rapidly.</p>
        </div>
        <div className='mt-[70px] grid grid-cols-3 gap-[50px] 2xl:w-[80%] xl:w-[80%] lg:w-[80%] md:w-[90%] sm:w-[90%] xs:w-[90%] mx-auto'>
          <div className='2xl:col-span-1 xl:col-span-1 lg:col-span-1 md:col-span-1 sm:col-span-3 xs:col-span-3'>
            <div className=' flex items-center'>
              <img alt="@" src={imgUrl + "/icons1.png"} />
              <p className='ml-5 font-extrabold text-[16px] text-white font-MontBold'>For Software Companies</p>
            </div>
            <p className='mt-3 font-normal text-[16px] text-white opacity-70 font-Mont'>Integrate our Model APIs to build a Co-pilot for your SaaS product and transform your user interface into a human language driven interface. Your users can simply prompt the AI to execute their tasks on your SaaS product. This way they won’t find difficulty in using your product’s interface and you won’t worry about losing users due to a complex UI.</p>
          </div>
          <div className='2xl:col-span-1 xl:col-span-1 lg:col-span-1 md:col-span-1 sm:col-span-3 xs:col-span-3'>
            <div className=' flex items-center'>
              <img alt="@" src={imgUrl + "/icons2.png"} />
              <p className='ml-5 font-extrabold text-[16px] text-white font-MontBold'> For Users</p>
            </div>
            <p className='mt-3 font-normal text-[16px] text-white opacity-70 font-Mont'> Users can simply prompt the AI to execute their tasks on your SaaS product. This way they won’t find difficulty in using your product’s interface and you won’t worry about losing users due to a complex UI.</p>
          </div>
          <div className='2xl:col-span-1 xl:col-span-1 lg:col-span-1 md:col-span-1 sm:col-span-3 xs:col-span-3'>
            <div className=' flex items-center'>
              <img alt="@" src={imgUrl + "/icons3.png"} />
              <p className='ml-5 font-extrabold text-[16px] text-white font-MontBold'> For Developers</p>
            </div>
            <p className='mt-3 font-normal text-[16px] text-white opacity-70 font-Mont'> Synapse Copilot is designed to make your life easier by cutting down the learning curve and enabling you to build and customize your API scenarios rapidly.</p>
          </div>
          <div className='col-span-3 mt-10 2xl:hidden xl:hidden lg:hidden md:hidden sm:hidden xs:flex justify-end xs:justify-center'>
            <div onClick={handleClick} className=' rounded-3xl flex items-center border-2 cursor-pointer border-black py-2 w-[200px] justify-center bg-[#F5F5F5]'>
              <p className='text-16px font-semibold font-MontBold'>Go to Github </p>
              <img className='ml-5' alt="git" src={imgUrl + "/github.png"} />
            </div>
          </div>
        </div>

      </div>

      <div className='py-[80px] 2xl:w-[95%] xl:w-[90%] lg:w-[90%] md:w-[90%] sm:w-[90%] xs:w-[90%] mx-auto'>
        <div>
      <Slider {...settingsLeftToRight}>
    {images.map((item, index) => (
      <div key={index}>
       
        <div className='h-[70px] mr-10 xs:mr-2 flex items-center justify-center px-5 rounded-2xl bg-[#eae6e2]'>
          <>
        {item.name === "Zoho" || item.name === "Hub Spot" ?
        <img src={item.imge} alt={`@`} className='w-[80%] h-[80%] object-contain'/> :
      (
        <>
          <img src={item.imge} alt={`@`} className='max-w-[50px] max-h-[50px] object-cover'/>
          <p className='ml-5 font-MontBold font-extrabold text-[32px] xs:text-[24px] overflow-hidden whitespace-nowrap text-ellipsis'>{item.name}</p>
          </>
)}
</>
        </div>
      </div>
    ))}
    
  </Slider>
 
  </div>
  <div className='mt-[50px]'>
  <Slider {...settingsRightToLeft}>
    {images.map((item, index) => (
      <div key={index}>
        <div className='h-[70px] mr-10 xs:mr-2 flex items-center justify-center px-2 rounded-2xl bg-[#eae6e2]'> 
        <>
        {item.name === "Zoho" || item.name === "Hub Spot" ?
        <img src={item.imge} alt={`@ `} className='w-[80%] h-[80%] object-contain'/> :
      (
        <>
          <img src={item.imge} alt={`@ `} className='max-w-[50px] max-h-[50px] object-cover'/>
          <p className='ml-5 font-MontBold font-extrabold text-[32px] xs:text-[24px] overflow-hidden whitespace-nowrap text-ellipsis'>{item.name}</p>
          </>
)}
</>
        </div>
      </div>
    ))}
  </Slider>
  </div>
  {/* <div className='w-[95%] mx-auto mt-[60px] flex justify-end xs:justify-center'>
            <div onClick={handleClick} className='rounded-3xl cursor-pointer flex  items-center border-2 border-[#818181] py-1 w-[200px] justify-center bg-[#f8f8f8]'>
              <p className='text-16px font-semibold font-MontBold'>Go to Github </p>
              <img className='ml-5' alt="git" src={imgUrl + "/github.png"} />
            </div>
          </div> */}
           <div className='w-[95%] mx-auto xs:w-full flex justify-end  xs:justify-center mt-[60px]'>
          <div onClick={handleClick} className='rounded-3xl flex items-center border-2 cursor-pointer border-[#818181] py-1 w-[220px] justify-center bg-[#f8f8f8]'>
            <p className='text-16px font-semibold font-MontBold'>Go to Github</p>
            <img className='ml-5' alt="git" src={imgUrl + "/github.png"} />
          </div>
        </div>
      </div>
      </>


      {/*footer Container */}

      <div className='relative'>
        <img alt="foter" src={imgUrl + "/END.png"} className='w-full h-[400px] object-cover' />
        <div className='absolute w-full top-[90px] flex flex-col justify-center items-center'>
        <img alt="logo" src={imgUrl + "logoWhite.png"} className='w-[280px]  h-[65px]' />
        {/* <div onClick={handleClick} className='rounded-3xl mt-10 cursor-pointer flex items-center border-2 border-black py-2 w-[200px] justify-center bg-[#F5F5F5]'>
              <p className='text-16px font-semibold font-MontBold'>Go to Github </p>
              <img className='ml-5' alt="git" src={imgUrl + "/github.png"} />
              
            </div> */}
            <p className='text-[14px] xs:hidden mt-[60px] text-center w-full text-white font-Mont'>
          Copyright © 2024 | Agile Loop
          </p>
        </div>
        <p className='absolute bottom-3 2xl:hidden xl:hidden lg:hidden md:hidden sm:hidden xs:block text-[14px] text-center w-full text-white font-Mont'>
          Copyright © 2024 | Agile Loop
          </p>
      </div>
    </Layout>
  )
}

export default Home
